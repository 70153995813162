/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const Wrapper = ({ children }) => {
  return (
    <div
      css={css`
        padding: 0.5rem 5%;
      `}
    >
      {children}
    </div>
  );
};

export default Wrapper;
