/** @jsx jsx */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Global, css, jsx } from '@emotion/core';

import Header from './header';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Global
        styles={css`
          html {
            font-size: 20px;
          }
          body {
            font-family: 'Titillium Web', sans-serif;
            margin: 0;
            color: #0d0808;
            background-color: #f8f7f7;
          }

          a {
            color: #cc2f36;
          }

          a:active,
          a:hover {
            color: #f20418;
          }

          h1,
          h2,
          h3,
          h4 {
            font-family: Inconsolata, Lucida Console, Monaco, monospace;
          }
        `}
      />
      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
        `}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <main
          css={css`
            flex: 1 100%;
            min-height: 90vh;
          `}
        >
          {children}
        </main>
        <footer
          css={css`
            text-align: center;
            padding: 10px 5%;
            flex: 1 100%;
            margin-bottom: 0;
            background-color: #f8f7f7;
          `}
        >
          <p
            css={css`
              font-size: 0.6rem;
              padding: 0;
              margin: 0 0 0.5rem 0;
            `}
          >
            Site design and content &copy; 2014-{new Date().getFullYear()} Mark
            Lavin.{' '}
            <a href="http://thenounproject.com/term/death-star/32019/">
              Death Star icon
            </a>{' '}
            by <a href="http://www.iconoci.co.uk/">iconoci</a> used under CC
            BY-SA 3.0.
          </p>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
