/** @jsx jsx */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { css, jsx } from '@emotion/core';

const linkCSS = css`
  color: #f8f7f7;

  :hover,
  :active {
    color: #f8f7f7;
    text-decoration: none;
  }
`;

const breakpoint = '@media (min-width: 480px)';

const Header = ({ siteTitle }) => (
  <header
    css={css`
      background: #cc2f36;
      margin-bottom: 0;
      color: #f8f7f7;
      flex: 1 100%;
      padding: 0.5rem 5%;
      letter-spacing: 0.2em;
      text-align: left;
    `}
  >
    <h1
      css={css`
        margin: 0;
        padding: 0.5rem 0;
        font-size: 2.5rem;
      `}
    >
      <Link
        to="/"
        css={css`
          ${linkCSS}
          text-decoration: none;
          ${breakpoint} {
            ::before {
              content: '>>> ';
            }
          }
        `}
      >
        {siteTitle}
      </Link>
    </h1>
    <nav>
      <ul
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0.25rem 0;
          margin: 0;
          list-style-type: none;
          ${breakpoint} {
            flex-direction: row;
          }
        `}
      >
        <li
          css={css`
            ${breakpoint} {
              ::after {
                content: ' //';
              }
            }
          `}
        >
          <Link css={linkCSS} to="/about/">
            Developer
          </Link>
        </li>
        <li
          css={css`
            ${breakpoint} {
              padding-left: 0.25rem;
              ::after {
                content: ' //';
              }
            }
          `}
        >
          <Link css={linkCSS} to="/sharing/">
            Author
          </Link>
        </li>
        <li
          css={css`
            ${breakpoint} {
              padding-left: 0.25rem;
              ::after {
                content: ' //';
              }
            }
          `}
        >
          <Link css={linkCSS} to="/hobbies/">
            Hobbyist
          </Link>
        </li>
        <li
          css={css`
            ${breakpoint} {
              padding-left: 0.25rem;
            }
          `}
        >
          <Link css={linkCSS} to="/running/">
            Ironman
          </Link>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
